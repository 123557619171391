//
jQuery(document).ready(function($) {
    var componentHeader = $(".componentHeader");
    var componentMainMenu = $(".componentMenuMain");
    var componentLogo = $(".componentLogo");
    var navbarTogglerIcon = $(".navbar-toggler-icon");
    var siteMain = $(".site-main");

    if (componentHeader.length && siteMain.length) {
        var headerHeight = componentHeader.outerHeight();
        siteMain.css("padding-top", headerHeight + "px");
    }

    navbarTogglerIcon.css("filter", "brightness(0) invert(0)");

    if ($("body").hasClass("page-template-tpl-home")) {
        $(".navbar-toggler").on("click", function() {
            if (!componentHeader.hasClass("scrolled")) {
                componentMainMenu.toggleClass("negative");
                componentHeader.toggleClass("bg-white");
                componentLogo.toggleClass("filtered");
                if (componentLogo.hasClass("filtered")) {
                    componentLogo.css("filter", "brightness(0) invert(0)");
                } else {
                    componentLogo.css("filter", "none");
                }
            }
        });
    }

    $(window).on("scroll", function() {
        var scrollTop = $(this).scrollTop();
        var opacity = Math.min(scrollTop / 100, 1);
        componentHeader.css("background-color", "rgba(255, 255, 255, " + opacity + ")");
        console.log(opacity);
        if (opacity === 1) {
            componentMainMenu.addClass("negative");
            componentLogo.css("filter", "brightness(0) invert(0)");
            navbarTogglerIcon.css("filter", "brightness(0) invert(0)");
            componentHeader.css("box-shadow", "0 4px 6px rgba(0, 0, 0, 0.1)");
            componentHeader.addClass("scrolled");
        } else {
            if ($("body").hasClass("page-template-tpl-home")) {
                componentMainMenu.removeClass("negative");
            }
            componentLogo.css("filter", "none");
            componentHeader.css("box-shadow", "none");
            componentHeader.removeClass("scrolled");
        }
        navbarTogglerIcon.css("filter", opacity === 1 ? "brightness(0) invert(0)" : "none");
    });
});
//

